import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { GlobalContext } from '../context/GlobalContext';
import {
  PinterestShareButton,
  FacebookShareButton,
  TumblrShareButton,
  EmailShareButton,
} from 'react-share';
import anime from 'animejs';
import 'stylesheet/projects.scss';
import { LinkHoverColor, getVar, Portal, checkFile } from 'utils/common';
import { DirectionArrow, BackButton, Cross } from 'components/svg';
import { MediaCheck } from 'utils/mediacheck';
import { ResponsiveVH } from 'utils/responsive-vh';
import Link from 'gatsby-plugin-transition-link';
// import { ImageLoadClass } from 'utils/loader';
import ImageParser from 'components/imageParser';

import OverlayScrollbars from 'overlayscrollbars';
import SEO from 'components/seo';

const ProjectTemp = ({ location, data }) => {
  const url = location.href ? location.href : '';
  const context = useContext(GlobalContext);
  const projectInformationDom = React.createRef();
  const toggleInfo = (open) => {
    if (projectInformationDom.current) {
      if (open) {
        projectInformationDom.current.classList.add('open');
        document.body.classList.add('lock');
        context.disableScrollBody.disable({
          except: ['.project_information', '.project_information .os-content'],
        });
      } else {
        projectInformationDom.current.classList.remove('open');
        document.body.classList.remove('lock');
        context.disableScrollBody.enable();
      }
    }
  };
  useEffect(() => {
    toggleInfo(false);
    if (typeof window !== 'undefined')
      document.querySelector('#MobileMenu').classList.add('hide');
    const fitHeightInfo = new ResponsiveVH({ target: '.project_information' });
    const currentOS = OverlayScrollbars(
      document.querySelector(`.project_information`),
      {
        overflowBehavior: {
          x: 'hidden',
          y: 'scroll',
        },
        callbacks: {
          onScroll: (e) => {},
        },
        scrollbars: { autoHide: 'scroll' },
      }
    );
    // const imageLoader = new ImageLoadClass({
    //   parent: 'main#Project',
    //   postload: () => {
    //     if (document.querySelector('main#Project'))
    //       document
    //         .querySelector('main#Project')
    //         .classList.remove('imageloading');
    //   },
    //   post_imageload: (image) => {
    //     image.parentElement.classList.add('loaded');
    //   },
    // });
    return () => {
      if (typeof window !== 'undefined')
        document.querySelector('#MobileMenu').classList.remove('hide');
      if (document.querySelector(`.project_information`) && currentOS)
        currentOS.destroy();
      fitHeightInfo.kill();
      toggleInfo(false);
      // imageLoader.kill();
    };
  }, []);
  return (
    <GlobalContext.Consumer>
      {(context) => {
        const setting = data.setting.frontmatter;
        return (
          <Layout
            titleText={data.content.frontmatter.title}
            mainID={'Project'}
            location={location}
          >
            <SEO
              seo={data.content.frontmatter.seo}
              title={data.content.frontmatter.title}
              url={url}
            />
            <div className='imagescontainer'>
              {data.content.frontmatter.images.map((image, index) => (
                <div key={index}>
                  <ImageParser
                    className={`${image.position_x}${image.position_y}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      background: '#DDDDDD',
                    }}
                    imgStyle={{
                      objectFit: 'cover',
                      objectPosition: `${image.position_x} ${image.position_y}`,
                      width: '100%',
                      height: '100%',
                    }}
                    loading='eager'
                    durationfadein={100}
                    alt='Brownfox Studio'
                    relativepath={image.file.publicURL}
                    childImageSharp={image.file.childImageSharp}
                  />
                </div>
              ))}
            </div>
            <Portal>
              <div
                id='project_information'
                className='project_information'
                ref={projectInformationDom}
              >
                <div
                  className='closecover'
                  role='button'
                  aria-label='Close Information'
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.which === 8) toggleInfo(false);
                  }}
                  onClick={() => {
                    toggleInfo(false);
                  }}
                />
                <div className='wrapper'>
                  <div className='contentwrapper'>
                    <div className='title'>
                      <span>{data.content.frontmatter.title}</span>
                      <span>
                        {data.content.frontmatter.category} —{' '}
                        {data.content.frontmatter.date}
                      </span>
                    </div>
                    <div className='description'>
                      {data.content.frontmatter.information}
                    </div>
                    <div className='share'>
                      <div>{setting.shared_label.share}</div>
                      {setting.share_enable.facebook && (
                        <>
                          <span
                            className='socialshare_btn'
                            role='presentation'
                            onMouseEnter={(e) => {
                              LinkHoverColor(e);
                            }}
                            onMouseLeave={(e) => {
                              LinkHoverColor(e);
                            }}
                          >
                            <FacebookShareButton
                              url={`${context.weburl}/${data.content.fields.slug}`}
                              quote={`${context.webname} — ${data.content.frontmatter.title}`}
                            >
                              {setting.shared_label.facebook}
                            </FacebookShareButton>
                          </span>
                          <span>{', '}</span>
                        </>
                      )}
                      {setting.share_enable.pinterest && (
                        <>
                          <span
                            className='socialshare_btn'
                            role='button'
                            tabIndex={0}
                            onMouseEnter={(e) => {
                              LinkHoverColor(e);
                            }}
                            onMouseLeave={(e) => {
                              LinkHoverColor(e);
                            }}
                          >
                            <PinterestShareButton
                              url={`${context.weburl}/${data.content.fields.slug}`}
                              media={`${context.weburl}/${checkFile(
                                data.content.frontmatter.coverimage.file
                                  .publicURL
                              )}`}
                              windowWidth={1000}
                              windowHeight={730}
                            >
                              {setting.shared_label.pinterest}
                            </PinterestShareButton>
                          </span>
                          <span>{', '}</span>
                        </>
                      )}
                      {setting.share_enable.tumblr && (
                        <>
                          <span
                            className='socialshare_btn'
                            role='button'
                            tabIndex={0}
                            onMouseEnter={(e) => {
                              LinkHoverColor(e);
                            }}
                            onMouseLeave={(e) => {
                              LinkHoverColor(e);
                            }}
                          >
                            <TumblrShareButton
                              url={`${context.weburl}/${data.content.fields.slug}`}
                              title={`${context.webname} — ${data.content.frontmatter.title}`}
                              windowWidth={660}
                              windowHeight={460}
                            >
                              {setting.shared_label.tumblr}
                            </TumblrShareButton>
                          </span>
                          <span>{', '}</span>
                        </>
                      )}

                      {setting.share_enable.mail && (
                        <span
                          className='socialshare_btn'
                          role='presentation'
                          onMouseEnter={(e) => {
                            LinkHoverColor(e);
                          }}
                          onMouseLeave={(e) => {
                            LinkHoverColor(e);
                          }}
                        >
                          <EmailShareButton
                            url={`${context.weburl}${data.content.fields.slug}`}
                            subject={`Check Out, ${context.webname} — ${data.content.frontmatter.title}`}
                            body={`Check Out, ${context.webname} — ${data.content.frontmatter.title}`}
                          >
                            {setting.shared_label.mail}
                          </EmailShareButton>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className='closebutton'
                  role='button'
                  tabIndex={0}
                  onClick={() => {
                    toggleInfo(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.which === 8) toggleInfo(false);
                  }}
                  onMouseEnter={LinkHoverColor}
                  onMouseLeave={LinkHoverColor}
                >
                  <Cross />
                </div>
              </div>

              <div
                className='viewinfo-project'
                role='button'
                tabIndex={0}
                onClick={() => {
                  toggleInfo(true);
                }}
                onKeyDown={(e) => {
                  if (e.which === 32) toggleInfo(true);
                }}
                onMouseEnter={LinkHoverColor}
                onMouseLeave={LinkHoverColor}
              >
                <span>{setting.shared_label.viewinfo}</span>
                <DirectionArrow />
              </div>
              <Link
                className='mobilebackbutton'
                exit={{
                  length: 1,
                  zIndex: 2,
                  trigger: ({ exit, node }) => {
                    if (typeof window !== 'undefined') {
                      document.body.classList.add('transitioning');
                      context.toggleMobileMenu(false);

                      if (MediaCheck.touch()) {
                      }
                      const scrollTop =
                        (document.scrollingElement &&
                          document.scrollingElement.scrollTop) ||
                        document.body.scrollTop ||
                        window.pageYOffset;

                      node.style.overflowY = 'hidden';
                      node.style.height = '100vh';
                      node.scrollTop = scrollTop;

                      anime({
                        targets: node.querySelector('main'),
                        translateX: {
                          value: [0, getVar.windowW()],
                          duration: 750,
                          delay: 0,
                          easing: 'easeInOutCubic',
                        },
                      });
                      anime({
                        targets: document.querySelector('.viewinfo-project'),
                        translateX: {
                          value: [0, getVar.windowW()],
                          duration: 750,
                          delay: 0,
                          easing: 'easeInOutCubic',
                        },
                      });
                      anime({
                        targets: document.querySelector('.mobilebackbutton'),
                        translateX: {
                          value: [0, getVar.windowW()],
                          duration: 750,
                          delay: 0,
                          easing: 'easeInOutCubic',
                        },
                      });

                      node.querySelector('main').style.boxShadow =
                        '0px 0px 20px rgba(0, 0, 0, 0.25)';
                    }
                  },
                }}
                entry={{
                  delay: 0,
                  zIndex: 1,
                }}
                to='/projects'
                style={{ stroke: context.main_color }}
              >
                <BackButton />
              </Link>
            </Portal>
          </Layout>
        );
      }}
    </GlobalContext.Consumer>
  );
};

export const query = graphql`
  query($slug: String!) {
    setting: markdownRemark(
      frontmatter: {
        issetting: { eq: true }
        contenttype: { eq: "general_setting" }
      }
    ) {
      html
      frontmatter {
        shared_label {
          viewinfo
          share
          facebook
          mail
          pinterest
          tumblr
        }
        share_enable {
          facebook
          mail
          pinterest
          tumblr
        }
      }
    }
    content: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        coverimage {
          file {
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
              )
            }
          }
        }
        date(formatString: "YYYY")
        information
        category
        images {
          position_x
          position_y
          file {
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        seo {
          seo_keywords
          seo_shortdesc
          seo_image {
            publicURL
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;

export default ProjectTemp;